/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import Sidebar from "./Sidebar/Sidebar"
import styled from 'styled-components'

const Layout = ({ children }) => {
  useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const LayoutWrapper = styled.div`
    @media screen and (min-width: 768px) {
      display: flex;
      margin: 0 auto;
      max-width: 1290px;
    }
  `

  const MainContent = styled.div`
    margin: 1rem 0;
    width: 100%;

    @media screen and (min-width: 768px) {
      margin-right: 1.875rem;
      max-width: 70%;
      padding-left: 1rem;
    }

    @media screen and (min-width: 1200px) {
      margin-top: 2.6875rem;
      margin-right: 3.75rem;
      max-width: 930px;
      padding-left: 0;
    }
  `

  return (
    <>
      <LayoutWrapper>
        <MainContent>
          <main>{children}</main>
        </MainContent>

        <Sidebar />
      </LayoutWrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

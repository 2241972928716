// import React, { useContext } from 'react'
import React from 'react'
import classes from './Sidebar.module.css'
import ad1 from '../../images/placement-area-ad1.png'
import ad2 from '../../images/placement-area-ad2.png'

// import { GlobalStateContext, GlobalDispatchContext } from '../../context/GlobalContextProvider'

const Sidebar = () => {

  // const state = useContext(GlobalStateContext)
  // const dispatch = useContext(GlobalDispatchContext)

  // console.log(state)
  // console.log(dispatch)

  return (
    <div className={classes.Sidebar}>
      <img
        src={ad1}
        alt="ad-1"
      />

      <img
        src={ad2}
        alt="ad-2"
      />

      {/* {state.theme} */}

      {/* <button type="button" onClick={() => {
        dispatch({type: 'TOGGLE_THEME'})
      }}>Toggle Theme</button> */}
    </div>
  )
}

export default Sidebar
